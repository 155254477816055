import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import PluginCards from 'components/Quick_Installation/Live_Video/PluginCards';
import TimeLine from 'components/Products/CommentList/HomekitAlexaList';
import AVCards from 'components/Quick_Installation/Live_Video/AVCards';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Display your Cameras Video inside your Browser",
  "path": "/Quick_Installation/Live_Video/",
  "dateChanged": "2017-11-22",
  "author": "Mike Polinowski",
  "excerpt": "Use the Flash Video Plugin to view your Camera's Live Video",
  "image": "./QI_SearchThumb_LiveVideo.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='Display your Cameras Video inside your Browser' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/' locationFR='/fr/Quick_Installation/Live_Video/' crumbLabel="Live Video" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "display-your-cameras-video-inside-your-browser",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#display-your-cameras-video-inside-your-browser",
        "aria-label": "display your cameras video inside your browser permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Display your Cameras Video inside your Browser`}</h1>
    <h2 {...{
      "id": "html5-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#html5-video",
        "aria-label": "html5 video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTML5 Video`}</h2>
    <p>{`Starting with version 2.6 of the web interface, all `}<strong parentName="p">{`Full HD`}</strong>{` camera models support `}<strong parentName="p">{`HTML5 Video`}</strong>{` for `}<strong parentName="p">{`plugin-free`}</strong>{` playback of the video stream. This feature is enabled by default and can be set via the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Video/"
      }}>{`Multimedia/Video Menu`}</a>{`.`}</p>
    <h2 {...{
      "id": "flash-video-plugin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#flash-video-plugin",
        "aria-label": "flash video plugin permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flash Video Plugin`}</h2>
    <p>{`All INSTAR HD cameras can display their video stream inside a modern web browser (like Firefox, Chrome or Edge) without the need for a video plugin. Just activate the MJPEG mode for this - e.g. in the Multimedia/Video Menu of your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Multimedia/Video/"
      }}>{`720p Camera`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Multimedia/Video/"
      }}>{`1080p Camera`}</a>{`. But you will lose the h.264 video compression and might start to suffer from bandwidth bottlenecks inside your network. We highly recommend, that you should install the `}<a parentName="p" {...{
        "href": "https://get.adobe.com/flashplayer/"
      }}>{`Adobe Flash Video Plugin`}</a>{` and use the h.264 video stream instead. This plugin is already installed on most systems and your camera is configured to use it by default - you should be able to see the video stream at once, after loading your cameras web user interface.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4fb11b808ccaff17e29f19e60012679b/00d43/Flash.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACLElEQVQoz3WOzW4SYRiF506Md6CJ8SJMXHbpzpXxBkxq0sRVoRWYutH+pGlrbGORKfRnYLBowATS8tMRSqgFOlM7rVDKUGAYXPgYxmJk4eLJ9+a87znnE16Oj7MwNYXb48EninhFEY/Hg9cnMulyMy2KiKKIz+dz3gEul8u5Geoer9eZJyYmEJberrDmX0eSAizNzbIyP0/gg8Re6gsf5S3k5WU2JAlFUYjFYsTjcSKRCMFgkFAw6Oxiu7ukkkn8fj/Ceug9sxvzLM5M8vTuHZ49eMjrN694t+bm07ZEcmHRKQuFNgkEAihRhUQigSzLbO2EkZUo22EFZTdGOBxBiESjbIZXWXC/4Mm9+zy6fYu5meesSn729tOkVJXPiQSZTJZMJkOhUEBVVQ5UFbVw6HCQP+TrYZG9/X2EUumIQjFPLpsmviOTkGUqx8foZwYnpRKVgEQ6myOby5HJpJ2warWKpmnouoau/eFU1ymXywiGcU6+WOJbVcNsd2h3uzQaDS4vL7mq19GLRWd/+v0MwzCo1+vO7n8ITdMkXzriqFKlVK5Q1nQaZotOp0O3a9Hr22i1FsWzJtet6xu96zCY/9Ju0+v1EKyeTa1pOlw0mjQHpptjy7JuzB2HoXmgD35qnF9wUavTaJpcNU36/T6Cbdsjrf+2DwOHQX/maywbrB8x2pkxzlOPMdNj2NVp7P6v0cBR42jgSFHPxmrXaRlpaidJGqcpOlcV7P5PfgNY+qxiBcYjRQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4fb11b808ccaff17e29f19e60012679b/e4706/Flash.avif 230w", "/en/static/4fb11b808ccaff17e29f19e60012679b/d1af7/Flash.avif 460w", "/en/static/4fb11b808ccaff17e29f19e60012679b/7f308/Flash.avif 920w", "/en/static/4fb11b808ccaff17e29f19e60012679b/445fc/Flash.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4fb11b808ccaff17e29f19e60012679b/a0b58/Flash.webp 230w", "/en/static/4fb11b808ccaff17e29f19e60012679b/bc10c/Flash.webp 460w", "/en/static/4fb11b808ccaff17e29f19e60012679b/966d8/Flash.webp 920w", "/en/static/4fb11b808ccaff17e29f19e60012679b/a5d4d/Flash.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4fb11b808ccaff17e29f19e60012679b/81c8e/Flash.png 230w", "/en/static/4fb11b808ccaff17e29f19e60012679b/08a84/Flash.png 460w", "/en/static/4fb11b808ccaff17e29f19e60012679b/c0255/Flash.png 920w", "/en/static/4fb11b808ccaff17e29f19e60012679b/00d43/Flash.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4fb11b808ccaff17e29f19e60012679b/c0255/Flash.png",
              "alt": "Flash Video Plugin",
              "title": "Flash Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "other-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-options",
        "aria-label": "other options permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other Options`}</h2>
    <p>{`If for some reason you cannot use the Flash Video Plugin, there are three more options to display the video stream inside a web browser. You can use the Quick Time Plugin, an ActiveX Plugin (only works with Internet Explorer and Firefox) that comes with a couple of perks or use the above mentioned MJPEG mode to avoid using plugins all together.`}</p>
    <PluginCards mdxType="PluginCards" />
    <EuiSpacer size="xl" mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer size="s" mdxType="EuiSpacer" />
    <h2 {...{
      "id": "troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#troubleshooting",
        "aria-label": "troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting`}</h2>
    <p>{`The video plugin might be blocked by your Firewall or Virus scanner. The following articles will explain how to set up your security software to unblock it.`}</p>
    <AVCards mdxType="AVCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      